<template>
	<div>
		<div class="container">
			<div class="content">
				<img src="../../assets/order_pending.png" alt="payment" />
				<p>確認支付狀態中...</p>
			</div>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	name: 'OrderCancel',
	created() {
		const storeHash = this.$route.params.random
		const query = this.$route.query
		this.$api.order
			.cancelTransaction(query.code, {
				storeHash: storeHash,
				orderId: query.orderId,
				transactionId: query.transactionId,
			})
			.then(res => {
				this.$alert('取消付款成功', 'success')
				this.$router.replace(`/${this.$route.params.random}/Order`)
			})
			.catch(err => {
				this.$alert('取消付款出現異常!', 'danger')
				this.$store.commit('user/SET_CART_ITEM', [])
				this.$router.replace(`/${this.$route.params.random}/Store`)
			})
	},
}
</script>

<style scoped>
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

img {
	max-width: 100%;
	height: auto;
}

p {
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}
</style>
